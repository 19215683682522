import { TEN, TWO_POW96, ZERO } from "@/constants/math";

export const convertFP96 = (val?: bigint) => {
  if (!val) {
    return ZERO;
  }

  return ZERO.plus(val.toString()).div(TWO_POW96);
};

export const convertWithDecimals = (val?: bigint, decimals?: number) => {
  if (!Number.isFinite(decimals) || !val) {
    return ZERO;
  }

  return TEN.pow(-decimals!).times(val.toString());
};
