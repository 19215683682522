import * as React from "react";
const SvgInfo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-9-3v7h1v2h-4v-2h1v-5h-1V9h3Zm0-2V5h-2v2h2Z"
      clipRule="evenodd"
      opacity={0.8}
    />
  </svg>
);
export default SvgInfo;
