"use client";
import { cssVar } from "@marginly/ui/util/style";
import styled, { keyframes } from "styled-components";

import InfoIconUnstyled from "../../icons/info-icon";
const STRIPE_WIDTH = "110px";
const animateButtonPendingBackground = keyframes`
  0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-${STRIPE_WIDTH});
	}
`;

export const Background = styled.div`
  position: relative;
  transition: all 200ms ease-in-out;
  color: var(--text-primary);
  min-height: 100vh;
  min-height: 100dvh;

  .details {
    background: var(--fill-secondary);
  }
  &.warn {
    background: var(--background-negative);
  }

  &.good {
    background: var(--background-positive);
  }

  @media (max-width: 1024px) {
    min-height: 100vh;
    min-height: 100dvh;
    overflow-y: hidden;
  }
`;
interface TitleProps {
  marginTop?: number;
  marginTopMobile?: number;
}

export const Title = styled.h2.withConfig({
  shouldForwardProp: (propName) => {
    return propName === "children";
  },
})<TitleProps>`
  margin: 0;
  text-align: left;
  font-size: 32px;
  font-variation-settings: "wght" 700;
  line-height: 48px;
  letter-spacing: 0.32px;
  font-weight: normal;
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}px;` : "")};
  ${(props) =>
    props.marginTopMobile
      ? `@media (max-width: 1024px) { margin-top: ${props.marginTopMobile}px; }`
      : ""};

  @media (min-width: 1024px) {
    font-size: 40px;
  }
`;

export const Between = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const AllAssetsLink = styled.div`
  color: var(--text-secondary, rgba(66, 61, 60, 0.64));
  font-size: 16px;
  letter-spacing: 0.48px;
  cursor: pointer;
  padding-bottom: 4px;
  transition: 300ms ease-out;
  font-weight: 400;
  font-variation-settings: "wght" 700;
  &:hover {
    color: var(--text-secondary-hover, rgba(41, 35, 35, 0.72));
  }
`;

export const TradeCoinRow = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  align-items: center;

  .trade {
    &__icon {
      margin-right: 16px;
    }

    &__cointotals {
      margin-left: auto;
      div {
        text-align: right;
        &:first-child {
          font-weight: 500;
        }
      }
    }
    &__name {
    }
    &__code {
      color: var(--text-secondary);
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 12px;
      line-height: 16px;
    }
    &__percents {
      color: var(--text-negative);
      letter-spacing: 0.5px;
      font-size: 12px;
      line-height: 16px;
      &--good {
        color: var(--text-positive);
      }
    }
  }

  .token-symbol {
    overflow: hidden;
    color: var(--grey-dark, #72706e);
    font-feature-settings: "clig" off, "liga" off;
    text-overflow: ellipsis;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.5px;
  }

  .token-name {
    overflow: hidden;
    color: var(--text-primary, #000);
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.16px;
  }
`;

export const IconBack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #f5e5ea;
  width: 48px;
  height: 48px;
  background: var(--fill-secondary);

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    filter: invert(var(--svg-invert));
  }
  &.icon--good {
    // background: #e8eedf;
  }
`;

export const PressedTotals = styled.div`
  min-height: 48px;
  text-align: center;
  .date {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: var(--text-secondary);
  }
`;

export const Form = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: var(--background-elevated);
  color: var(--text-primary);
  border-radius: 48px 48px 0 0;
  padding: 24px;
  overflow: scroll;
  z-index: 9;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  && .input__area {
    padding-right: 136px;
  }

  & > * {
    flex-shrink: 0;
  }

  &::before {
    content: " ";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 14px;
    width: 32px;
    height: 4px;
    top: 10px;
    background: ${cssVar("--fill-secondary")};
  }

  @media (min-width: 1024px) {
    border-radius: 48px;
    padding: 24px 24px 32px;
    height: fit-content;
    margin: 0;
    position: relative;
    margin-bottom: 250px;
    overflow: visible;
    min-width: 360px;

    &::before {
      display: none;
    }
  }

  .action-button {
    width: 100%;
    .action__title {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.02em;
      font-variation-settings: "wght" 700;
      text-align: left;
      & + * {
        margin-top: 4px;
      }
    }

    .action__subbutton {
      background: white;
      padding: 0 12px 0 12px;
      height: 32px;
      width: 104px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      font-variation-settings: "wght" 700;
    }
    .action__subinfo {
      letter-spacing: 0.5px;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      span {
        color: var(--text-accent);
        font-variation-settings: "wght" 600;
        font-style: italic;
      }
    }
  }

  @media (max-width: 1024px) {
    .swap {
      position: absolute;
      left: 50%;
      bottom: 16px;
      transform: translateX(-50%);
      width: calc(100% - 48px);
      border-radius: 24px;
    }
  }
`;

export const OutOfForm = styled.div`
  @media (min-width: 1024px) {
    position: absolute;
    bottom: -24px;
    left: 0;
    width: 100%;
    transform: translateY(100%);
  }
`;

export const TradeActionButton = styled.button`
  border-radius: 24px;
  padding: 26px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 96px;
  overflow: hidden;
  isolation: isolate;
  border: 0;
  width: 100%;
  cursor: pointer;

  color: var(--text-invert-primary);
  background: var(--fill-primary);

  transition: 300ms ease-out;

  &:hover {
    background: var(--fill-primary-hover);
  }

  &:active {
    transform: scale(0.95);
  }

  .action__title {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    font-variation-settings: "wght" 700;
    margin-bottom: 4px;
    text-align: left;
  }

  .action__subbutton {
    background: white;
    padding: 0 12px 0 12px;
    height: 32px;
    width: 104px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-variation-settings: "wght" 700;
  }
  .action__subinfo {
    letter-spacing: 0.5px;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    span {
      color: var(--text-accent);
      font-variation-settings: "wght" 600;
      font-style: italic;
    }
  }

  &.pending {
    position: relative;
    pointer-events: none;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: calc(100% + ${STRIPE_WIDTH});
      background: repeating-linear-gradient(
        45deg,
        black 25%,
        black 50%,
        #585756 50%,
        #585756 75%
      );

      background-size: ${STRIPE_WIDTH} ${STRIPE_WIDTH};
      animation: ${animateButtonPendingBackground} 2s linear infinite;
    }
  }
  &.approve {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 64px;
    .overlay {
      font-variation-settings: "wght" 700;
      letter-spacing: 0.48px;
    }
  }

  .overlay {
    position: relative;
    z-index: 8;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.24;
    background: var(--fill-primary-hover);
  }

  &.toSwap {
    background: var(--fill-negative, #e54796);
    color: var(--text-primary-on-dark, #fff);
    .label {
      border-radius: var(--rounding-radius-m, 16px);
      background: var(--fill-elevated, #fff);
      box-shadow: 0px 4px 8px 0px rgba(51, 20, 0, 0.08),
        0px 2px 1px 0px rgba(51, 20, 0, 0.04);
      height: 32px;
      padding: 0 var(--spacing-space-12, 12px);

      p {
        color: var(--text-primary, #000);
      }
    }
  }

  .elevated .action {
    color: var(--text-on-light);
  }
`;

export const TransactionFee = styled.div`
  color: var(--text-secondary);
  letter-spacing: 0.5px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;

export const ChartContainer = styled.div`
  margin: 0 -24px;
  overflow: hidden;
  position: relative;
  padding-bottom: 96px;

  canvas {
    z-index: unset !important;
  }

  @media (min-width: 1024px) {
    margin: 0 0 8px;
    padding-bottom: 0;
  }
`;

export const TradeDesktopRow = styled.div`
  @media (max-width: 1024px) {
    .hide-mobile {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: minmax(0, 1.4fr) minmax(0, 1fr);
    gap: 48px;
    .hide-desktop {
      display: none;
    }
    .hide-mobile {
      display: block;
    }
    .reverse-container {
      display: flex;
      flex-direction: column;
      .tabs-container {
        order: 4;
      }
    }
    .candleinfo {
      order: 4;
    }
  }
`;

export const GraphSwitcher = styled.button`
  background-color: var(--fill-secondary);
  background-blend-mode: multiply;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 0;
  outline: 0;
  z-index: 9;
  background-position: center;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  cursor: pointer;
  flex-shrink: 0;
`;

export const DetailsUnit = styled.div`
  align-items: center;
  justify-content: space-between;
  display: none;
  padding: 8px 0;
  & > * {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  & + & {
    margin-top: 8px;
  }
`;

export const DetailsBlockHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  letter-spacing: 0.02em;
  font-size: 20px;
  line-height: 24px;
  font-variation-settings: "wght" 700;
  cursor: pointer;
`;

export const InfoIcon = styled(InfoIconUnstyled)`
  path {
    fill: var(--icon-primary);
  }
`;

export const DetailsBlock = styled.div`
  background-blend-mode: multiply;
  border-radius: 24px;
  padding: 12px 24px;
  width: 100%;

  &.expanded {
    ${InfoIcon} {
      display: none;
    }
    ${DetailsUnit} {
      display: flex;
      &:first-child {
        margin-top: 12px;
      }
    }
  }

  @media (min-width: 1024px) {
    background-blend-mode: multiply;
  }
  @media (max-width: 1024px) {
    background-blend-mode: normal;
    background: var(--fill-primary);
  }
`;

export const TradePendingWrapper = styled.div`
  position: fixed;
  background: var(--background-overlay);
  backdrop-filter: blur(8px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  z-index: 9999;

  &.withdraw-all {
    z-index: 9998;
  }
`;

export const TradePendingBody = styled.div`
  position: absolute;
  background: var(--background-elevated);
  color: var(--text-primary);
  box-shadow: 0px 8px 32px rgba(51, 20, 0, 0.16);
  border-radius: 32px;
  max-width: 420px;
  width: calc(100% - 16px);
  bottom: 8px;
  padding: 48px 24px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    bottom: auto;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.withdraw-all {
    transform: translate(-50%, 100%);
    transition: 0.3s ease-in-out;
    padding-bottom: 32px;

    @media (min-width: 1024px) {
      transform: translate(-50%, 150%);
    }

    &.visible {
      transform: translateX(-50%);

      @media (min-width: 1024px) {
        bottom: auto;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

export const TradePendingHeading = styled.div`
  font-variation-settings: "wght" 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.02em;
`;

const animatePendingCircles = keyframes`
  0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(50%);
	}
`;

const animatePendingCircles2 = keyframes`
  0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-50%);
	}
`;

export const TradePendingAnimation = styled.div`
  background: var(--fill-accent-secondary);
  mix-blend-mode: multiply;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  animation: ${animatePendingCircles} 1s alternate linear infinite;
`;

export const TradePendingAnimation2 = styled(TradePendingAnimation)`
  transform: translate(100%, -100%);
  animation: ${animatePendingCircles2} 1s alternate linear infinite;
`;

export const TradePendingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  position: relative;
  z-index: 8;
`;

export const CandleInfo = styled.div`
  min-height: 48px;
`;

export const CandleInfoRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const CandleInfoLabel = styled.div`
  font-family: "PlusJakartaSans", "Medium", sans-serif;
  color: var(--text-secondary);
  font-size: 12px;
  line-height: 16px;
`;

export const CandleInfoValue = styled.div`
  font-family: "PlusJakartaSans", "Medium", sans-serif;
  color: var(--text-primary);
  font-size: 16px;
  line-height: 24px;
`;

const WithdrawAllBase = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 2000;
`;

const WithdrawAllOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-overflay);
  backdrop-filter: blur(8px);
`;

const WithdrawAllContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 8px;
  right: 8px;
  background-color: #fff;
  padding: 48px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  text-align: center;
  letter-spacing: 0.02em;
  box-shadow: 0px 8px 32px rgba(51, 20, 0, 0.16);
  border-radius: 32px;
  background: var(--background-elevated);
  color: var(--text-primary);

  transform: translateY(100%);
  transition: 0.3s ease-in-out;

  &.visible {
    transform: translateY(0);
  }
`;

const WithdrawAllIcon = styled.div`
  margin-bottom: 28px;
`;
const WithdrawAllTitle = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-variation-settings: "wght" 700;
  margin-bottom: 12px;
`;
const WithdrawAllDesc = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-variation-settings: "wght" 500;
  margin-bottom: 48px;
`;

const WithdrawAllButton = styled.button`
  height: 64px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-variation-settings: "wght" 700;
  border-radius: 16px;
  border: none;
`;

const WithdrawAllActionButton = styled(WithdrawAllButton)`
  background: var(--fill-primary);
  color: var(--text-invert-primary);
  margin-bottom: 8px;
  cursor: pointer;
`;

const WithdrawAllCancelButton = styled(WithdrawAllButton)`
  background: var(--fill-secondary);
  color: var(--fill-primary);
  cursor: pointer;
`;

export const WithdrawAll = Object.assign(WithdrawAllBase, {
  Overlay: WithdrawAllOverlay,
  Container: WithdrawAllContainer,
  Icon: WithdrawAllIcon,
  Title: WithdrawAllTitle,
  Description: WithdrawAllDesc,
  ActionButton: WithdrawAllActionButton,
  CancelButton: WithdrawAllCancelButton,
});

export const TabsContainer = styled.div`
  display: flex;
  gap: 12px;
  .tabs {
    order: 0;
    flex-grow: 1;
  }
`;
